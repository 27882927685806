<template>
  <div v-if="startTime && finishTime" class="alert alert-primary">
    Completed on {{ finishTime | utcDateReadableLong }}
    at {{ finishTime | utcTimeReadable }}
    <div v-if="duration">Duration: {{ duration }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'SessionSummary',
  props: {
    startTime: {
      type: String,
      required: true,
    },
    finishTime: {
      type: String,
      required: true,
    },
  },
  computed: {
    duration() {
      const { startTime, finishTime } = this;
      if (startTime === '' || finishTime === '') return null;
      const s = dayjs(finishTime).diff(dayjs(startTime), 's');
      if (s < 86400) return new Date(s * 1000).toISOString().substr(11, 8);
      return [
        Math.floor(s / 60 / 60 / 24),
        Math.floor(s / 60 / 60) % 24,
        Math.floor(s / 60) % 60,
        s % 60,
      ].map((val) => (val < 10 ? `0${val}` : `${val}`)).join(':');
    },
  },
};
</script>
